import React, { useState, useEffect } from 'react';
import styles from '../css/home.module.css';

function Domande() {
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    const images = document.querySelectorAll(`.${styles.fadeIn}`);
    images.forEach(image => {
      image.classList.add(styles.visible);
    });
  }, []);

  const handleToggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <main className={styles.mainContent}>
      <h1 className={styles.title}>Amici veri, divertimento vero</h1>
      <a href="https://anonymussss.web.app/" className={styles.downloadButton}>Scarica l'app!</a>
      <p className={styles.fadeIn}>
        Usalo per le tue domande anonime su Instagram. Adatto per profili gossip e utenza privata.
      </p>
      <button className={styles.toggleButton} onClick={handleToggleDetails}>
        {showDetails ? 'Mostra di meno' : 'Scopri di più'}
      </button>
      <div className={`${styles.details} ${showDetails ? styles.show : ''}`}>
        <h2 className={styles.subTitle}><strong>Benvenuti nel futuro dei social media anonimi!</strong></h2>
        <p className={styles.subText}><strong>Scopri le funzionalità avanzate e le animazioni spettacolari che rendono unica la nostra piattaforma.</strong></p>
        <p className={styles.subText}><strong>Con questo social, puoi inviare messaggi anonimi, immagini, video e audio alimentati con l'IA. Sarà il miglior social di messaggistica anonima con altre funzionalità avanzate. Siate liberi di fare tutto quello che volete (in rispetto però delle norme della community), e tutto quanto senza sapere chi siete.</strong></p>
        <div className={styles.animations}>
          <img 
            src="https://framerusercontent.com/images/IqcccrhYwPEBRCzLBmmfUIB3fc.jpg" 
            alt="Animazione futuristica 1" 
            className={styles.animation} 
          />
          <img 
            src="https://framerusercontent.com/images/tb4WP0Q85cy7h7y19oHHr5Ps8pQ.jpg" 
            alt="Animazione futuristica 2" 
            className={styles.animation} 
          />
        </div>
      </div>
    </main>
  );
}

export default Domande;





