import * as React from "react";
import { FaBars } from "react-icons/fa"; // Importa l'icona dell'hamburger
import { FaApple, FaAndroid } from "react-icons/fa"; // Importa le icone di Apple e Android
import './navbar.css';
import logo from '../img/logo.jpeg';

const NavigationMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <img src={logo} alt="Logo" className="navbar-logo" /> {/* Aggiungi il percorso del logo */}
        <span>Anonymouss</span>
      </div>
      <div className={`navbar-content ${isMenuOpen ? 'open' : ''}`}>
        <a href="#" className="navbar-link active">Home</a>
        <a href="#" className="navbar-link">Cos'è</a>
        <a href="#" className="navbar-link">Contattaci</a>
        <a href="#" className="navbar-link">Termini e supporto</a>
      </div>
      <div className="navbar-buttons">
        <button className="navbar-button">
          <FaApple />
        </button>
        <button className="navbar-button">
          <FaAndroid />
        </button>
      </div>
      <button className="navbar-toggle" onClick={toggleMenu}>
        <FaBars />
      </button>
    </nav>
  );
};

export default NavigationMenu;




















