import React, { useEffect } from 'react';
import styles from './QRCodeSection.module.css'; // Importa il modulo CSS
import qrcode from '../img/qrcode.png'

const QRCodeSection = () => {
  useEffect(() => {
    const handleMouseMove = (e) => {
      const shapes = document.querySelectorAll(`.${styles.shape}`);
      shapes.forEach((shape) => {
        const speed = shape.getAttribute('data-speed');
        const x = (window.innerWidth - e.pageX * speed) / 100;
        const y = (window.innerHeight - e.pageY * speed) / 100;
        shape.style.transform = `translateX(${x}px) translateY(${y}px)`;
      });
    };

    const handleTouchMove = (e) => {
      const shapes = document.querySelectorAll(`.${styles.shape}`);
      shapes.forEach((shape) => {
        const speed = shape.getAttribute('data-speed');
        const x = (window.innerWidth - e.touches[0].pageX * speed) / 100;
        const y = (window.innerHeight - e.touches[0].pageY * speed) / 100;
        shape.style.transform = `translateX(${x}px) translateY(${y}px)`;
      });
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('touchmove', handleTouchMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  return (
    <div className={styles.qrSection}>
      <h1 className={styles.title}>Inquadra il QR Code</h1>
      <div className={styles.qrContainer}>
        <img src={qrcode} alt="QR Code" className={styles.qrImage} />
      </div>
      <div className={styles.shapes}>
        
      </div>
    </div>
  );
};

export default QRCodeSection;
