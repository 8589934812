import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useParams } from 'react-router-dom';
import Home from './components/home';
import Message from './components/css/message';
import Parse from 'parse';

Parse.initialize("VLKBnzLuFHycDSBH1me0xnfqBuqIy3HOgbBZrtPH", "WhkPuzkh26x2uzbBX5NcbRNHx0A9IRx1q8q3U86o");
Parse.serverURL = 'https://parseapi.back4app.com';



function UserMessage() {
  const { username } = useParams();
  const [userExists, setUserExists] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const query = new Parse.Query('CustomUser');
      query.equalTo('username', username);
      const user = await query.first();
      setUserExists(!!user);
    };

    

    fetchUser();
  }, [username]);

  if (userExists === null) {
    return <div>Loading...</div>; // Mostra un caricamento mentre si verifica l'utente
  }

  if (!userExists) {
    return <Navigate to="/home" />;
  }

  return <Message />;
}

function App() {
  return (
    <Routes>
      <Route path="/home" element={<Home />} />
      <Route path="/:username" element={<UserMessage />} />
      <Route path="*" element={<Navigate to="/home" />} />
    </Routes>
  );
}

export default App;


