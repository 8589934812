import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Parse from 'parse';
import styles from './App.module.css'; // Importa il modulo CSS

const Messages = () => {
  const { username } = useParams(); // Ottieni il nome utente dall'URL
  const [message, setMessage] = useState('');
  const [mode, setMode] = useState('text'); // Stato per la modalità selezionata
  const [file, setFile] = useState(null);

  const handleNextMode = () => {
    const modes = ['text', 'image', 'video', 'audio'];
    const currentIndex = modes.indexOf(mode);
    const nextIndex = (currentIndex + 1) % modes.length;
    setMode(modes[nextIndex]);
    setFile(null); // Resetta il file selezionato quando cambia modalità
  };

  const handlePrevMode = () => {
    const modes = ['text', 'image', 'video', 'audio'];
    const currentIndex = modes.indexOf(mode);
    const prevIndex = (currentIndex - 1 + modes.length) % modes.length;
    setMode(modes[prevIndex]);
    setFile(null); // Resetta il file selezionato quando cambia modalità
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const renderFilePreview = () => {
    if (!file) return null;

    const fileURL = URL.createObjectURL(file);
    if (mode === 'image') {
      return <img src={fileURL} alt="Preview" className={styles.preview} />;
    } else if (mode === 'video') {
      return <video controls className={styles.preview}><source src={fileURL} type={file.type} /></video>;
    } else if (mode === 'audio') {
      return <audio controls className={styles.preview}><source src={fileURL} type={file.type} /></audio>;
    }
  };

  const handleSubmit = async () => {
    const browserData = navigator.userAgent;
    const ipAddress = await fetch('https://api.ipify.org?format=json').then(res => res.json()).then(data => data.ip);

    if (mode === 'text') {
      // Salva il messaggio di testo direttamente nel database
      const ContentData = Parse.Object.extend('ContentData');
      const contentData = new ContentData();

      contentData.set('username', username);
      contentData.set('date', new Date());
      contentData.set('type', mode);
      contentData.set('message', message);
      contentData.set('browserData', browserData);
      contentData.set('ipAddress', ipAddress);

      try {
        await contentData.save();
        alert('Messaggio inviato con successo!');
        setMessage('');
      } catch (error) {
        console.error('Errore durante il salvataggio del messaggio:', error);
        alert('Errore durante l\'invio del messaggio. Riprova.');
      }
    } else {
      // Carica il file e salva i dati tramite Cloud Code
      const fileName = file ? file.name : null;
      const base64 = file ? await toBase64(file) : null;

      try {
        await Parse.Cloud.run("uploadContent", {
          fileName,
          base64,
          username,
          type: mode,
          message,
          browserData,
          ipAddress
        });
        alert('Messaggio inviato con successo!');
        setMessage('');
        setFile(null);
      } catch (error) {
        console.error('Errore durante il salvataggio del messaggio:', error);
        alert('Errore durante l\'invio del messaggio. Riprova.');
      }
    }
  };

  const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = error => reject(error);
  });

  return (
    <div className={styles.container}>
      <div className={styles['message-box']}>
        <div className={styles['message-header']}>
          <div className={styles['profile-pic']} alt="Immagine del profilo"></div>
          <div>
            <div className={styles.username}>@{username}</div> {/* Usa il nome utente ottenuto dall'URL */}
            <div className={styles['prompt-text']}><b>Mandami messaggi anonimi!</b></div>
          </div>
        </div>
        {mode === 'text' ? (
          <input style={{fontSize: '25px', color: 'black', fontFamily: 'Arial Black'}}
            type="text" 
            className={styles['message-input']} 
            placeholder="mandami messaggi anonimi..." 
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        ) : (
          <div className={styles['file-upload']}>
            <input 
              type="file" 
              accept={mode === 'image' ? 'image/png, image/jpeg, image/jpg' : mode === 'video' ? 'video/mp4' : 'audio/ogg, audio/mp4'}
              onChange={handleFileChange} 
              className={styles['custom-file-input']}
              id="file-upload"
            />
            <label htmlFor="file-upload" className={styles['custom-file-label']}>
              {file ? file.name : 'Invia una tua foto'}
            </label>
            {renderFilePreview()}
          </div>
        )}
        <div className={styles['mode-selector']}>
          <button onClick={handlePrevMode} className={styles['nav-button']}>
            <i className="fas fa-arrow-left"></i>
          </button>
          <button onClick={handleNextMode} className={styles['nav-button']}>
            <i className="fas fa-arrow-right"></i>
          </button>
        </div>
        <div className={styles['button-group']}>
          <button className={`${styles['upload-button']} ${styles['shake-animation']}`}>
            {mode === 'text' && 'Scrivi un testo'}
            {mode === 'image' && 'Invia un\'immagine'}
            {mode === 'video' && 'Invia un video'}
            {mode === 'audio' && 'Invia un audio'}
          </button>
        </div>
      </div>
      <button 
        style={{
          backgroundColor: 'black',
          color: 'white',
          borderRadius: '20px',
          padding: '10px 20px',
          border: 'none',
          cursor: 'pointer',
          marginTop: '10px'
        }}
        onClick={handleSubmit}
      >
        Invia segnale!
      </button>
      <div className={styles.footer}>
        <p><i className="fas fa-lock"></i> Anonymous Messages. Copyright (C) 2024, NGL Cila and Cila Studios</p>
        <p><i className="fas fa-thumbs-down"></i> 223 persone hanno appena cliccato il pulsante👇</p>
        <a href="https://anonymouss.me" className={styles.button}>Ottieni i tuoi messaggi!</a>
        <p className={styles.privacy}>Termini  Privacy</p>
      </div>
    </div>
  );
};

export default Messages;















