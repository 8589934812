import React from 'react';
import styles from './Footer.module.css'; // Importa il modulo CSS

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <p>© 2024 Anonymous. NGL Cila e Cila Studios. Tutti i diritti riservati.</p>
      <div className={styles.links}>
        <a href="/chisiamo">Chi siamo</a>
        <a href="/privacy">Termini e condizioni dati personali</a>
      </div>
    </footer>
  );
};

export default Footer;
